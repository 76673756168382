import { ScrumboardAttachment } from "src/app/pages/admin/scrumboard/interfaces/scrumboard-attachment.interface";

export const solicitudesLabels = [
  {
    id: 0,
    text: 'error_clave_pin',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Error con la clave PIN'
  },
  {
    id: 1,
    text: 'failed_without_retry',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Fallo sin reintento'
  },
  {
    id: 111,
    text: 'failed_with_retry',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Fallo con reintento'
  },
  {
    id: 112,
    text: 'max_entries_otp_seguridad_social',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'new_releases',
    label: 'Max intentos OTP Seg Social'
  },
  {
    id: 113,
    text: 'max_entries_otp_aeat',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'new_releases',
    label: 'Max intentos OTP AEAT'
  },
  {
    id: 114,
    text: 'unknow_error_seguridad_social',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'bug_report',
    label: 'Error desconocido en Seg Social'
  },
  {
    id: 115,
    text: 'unknow_error_aeat',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'bug_report',
    label: 'Error desconocido en AEAT'
  },

  {
    id: 116,
    text: 'user_banned_clave_pin',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'not_interested',
    label: 'Clave Pin baneada'
  },

  {
    id: 117,
    text: 'max_entries_data_aeat',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Max intentos AEAT'
  },
  {
    id: 118,
    text: 'max_entries_data_seguridad_social',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Max intentos Seg Social'
  },
  {
    id: 119,
    text: 'invalid_otp',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'OTP inválido'
  },
  {
    id: 120,
    text: 'waiting_acceptance_file_signed',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'timelapse',
    label: 'Esperando firma de certificado'
  },
  {
    id: 121,
    text: 'no_clave_pin_service',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Sin servicio Clave PIN'
  },
  {
    id: 122,
    text: 'waiting_video',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'cancel',
    label: 'Esperando Video ID'
  },
  
  
  {
    id: 2,
    text: 'bad_phone',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Teléfono erróneo'
  },
  {
    id: 3,
    text: 'failed_documents_downloaded',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Fallo al descargar Documentos'
  },
  {
    id: 4,
    text: 'invalid_otp_aeat',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'OTP de AEAT inválido'
  },
  {
    id: 5,
    text: 'invalid_otp_seguridad_social',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'OTP de Seg. Social inválido'
  },
  {
    id: 6,
    text: 'max_entries_otp',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'not_interested',
    label: 'Max intentos OTP'
  },

  {
    id: 7,
    text: 'abandoned_after_bad_phone',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'phonelink_erase',
    label: 'Abandonado (bad phone)'
  },
  {
    id: 8,
    text: 'abandoned_after_terms',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (términos y condiciones)'
  },
  {
    id: 9,
    text: 'abandoned_after_invalid_otp_seguridad_social',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (invalid OTP SS)'
  },
  {
    id: 10,
    text: 'abandoned_after_invalid_otp_aeat',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (invalid OTP AEAT)'
  },
  {
    id: 11,
    text: 'abandoned_after_waiting_otp_aeat',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (waiting OTP AEAT)'
  },
  {
    id: 121,
    text: 'soliciabandoned_after_terms_and_conditions',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (waiting Terms)'
  },
  {
    id: 1111,
    text: 'waiting_otp',
    textClass: 'text-teal',
    bgClass: 'bg-teal-500/10',
    previewClass: 'bg-teal',
    icon: 'timelapse',
    label: 'Esperando OTP'
  },
  {
    id: 1112,
    text: 'waiting_certificate',
    textClass: 'text-teal',
    bgClass: 'bg-teal-500/10',
    previewClass: 'bg-teal',
    icon: 'timelapse',
    label: 'Esperando Certificado'
  },
  {
    id: 1113,
    text: 'waiting_password',
    textClass: 'text-orange',
    bgClass: 'bg-orange-500/10',
    previewClass: 'bg-orange',
    icon: 'timelapse',
    label: 'Esperando password'
  },
  {
    id: 1114,
    text: 'certificate_created',
    textClass: 'text-green',
    bgClass: 'bg-green-500/10',
    previewClass: 'bg-green',
    icon: 'check',
    label: 'Certificado creado'
  },
  {
    id: 1115,
    text: 'no_more_phone',
    textClass: 'text-orange',
    bgClass: 'bg-orange-500/10',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Teléfono erróneo'
  },

  
  {
    id: 1116,
    text: 'success_login_bde',
    textClass: 'text-teal',
    bgClass: 'bg-teal-500/10',
    previewClass: 'bg-teal',
    icon: 'check',
    label: 'Login a BDE exitoso'
  },
  {
    id: 117,
    text: 'rejected_identity',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Identidad rechazada'
  },

  
  {
    id: 12,
    text: 'abandoned_after_waiting_otp_seguridad_social',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (waiting OTP SS)'
  },


  {
    id: 13,
    text: 'waiting_login_seguridad_social',
    textClass: 'text-yellow-500',
    bgClass: 'bg-yellow-500/10',
    previewClass: 'bg-yellow-500',
    icon: 'timelapse',
    label: 'Esperando login SS'
  },
  {
    id: 14,
    text: 'waiting_login_aeat',
    textClass: 'text-yellow-500',
    bgClass: 'bg-yellow-500/10',
    previewClass: 'bg-yellow-500',
    icon: 'timelapse',
    label: 'Esperando login AEAT'
  },
  {
    id: 15,
    text: 'waiting_otp_aeat',
    textClass: 'text-yellow-500',
    bgClass: 'bg-yellow-500/10',
    previewClass: 'bg-yellow-500',
    icon: 'timelapse',
    label: 'Esperando OTP AEAT'
  },
  {
    id: 16,
    text: 'waiting_otp_seguridad_social',
    textClass: 'text-yellow-500',
    bgClass: 'bg-yellow-500/10',
    previewClass: 'bg-yellow-500',
    icon: 'timelapse',
    label: 'Esperando OTP SS'
  },


  {
    id: 17,
    text: 'saved_otp',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple',
    icon: 'timelapse',
    label: 'OTP guardado'
  },
  {
    id: 171,
    text: 'picked_up_otp',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple',
    icon: 'timelapse',
    label: 'OTP recogido'
  },


  {
    id: 18,
    text: 'terms_and_conditions',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple',
    icon: 'timelapse',
    label: 'Términos y condiciones'
  },

  {
    id: 19,
    text: 'waiting_documents',
    textClass: 'text-cyan',
    bgClass: 'bg-cyan-light',
    previewClass: 'bg-cyan',
    icon: 'timelapse',
    label: 'Esperando documentos'
  },
  {
    id: 20,
    text: 'created',
    textClass: 'text-cyan',
    bgClass: 'bg-cyan-light',
    previewClass: 'bg-cyan',
    icon: 'fiber_new',
    label: 'Solicitud creada'
  },

  {
    id: 21,
    text: 'all_documents_downloaded',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    icon: 'check_circle',
    label: 'Todos los documentos descargados'
  },
  {
    id: 22,
    text: 'partial_documents_downloaded',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    icon: 'check_circle',
    label: 'Algunos Documentos descargados'
  },
  {
    id: 23,
    text: 'parcial_documents_downloaded',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    icon: 'check_circle',
    label: 'Algunos Documentos descargados'
  },
  {
    id: 24,
    text: 'all_documents_downloaded_and_deleted',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    icon: 'delete',
    label: 'Todos descargados y eliminados'
  },
  {
    id: 25,
    text: 'partial_documents_downloaded_and_deleted',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    icon: 'delete',
    label: 'Algunos descargados y eliminados'
  },
  {
    id: 26,
    text: 'updated_data',
    textClass: 'text-blue-500',
    bgClass: 'bg-blue-500/10',
    previewClass: 'bg-blue-500',
    icon: 'check',
    label: 'Datos del cliente actualizados'
  },
  {
    id: 27,
    text: 'resend_otp',
    textClass: 'text-blue-500',
    bgClass: 'bg-blue-500/10',
    previewClass: 'bg-blue-500',
    icon: 'refresh',
    label: 'OTP reenviado'
  },
  {
    id: 28,
    text: 'consent_process_canceled',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Solicitud cancelada'
  },
  {
    id: 29,
    text: 'abandoned_after_waiting_otp',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange',
    icon: 'warning',
    label: 'Abandonado (waiting otp)'
  },
  {
    id: 30,
    text: 'rejected_rmc_validation',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red',
    icon: 'cancel',
    label: 'Apoderamiento rechazado'
  },
];

export const solicitudesTypes = [

  {
    text: 'ClavePIN',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple',
    prop: 'pin'
  },
  {
    text: 'SMS',
    textClass: 'text-cyan',
    bgClass: 'bg-cyan-light',
    previewClass: 'bg-cyan',
    prop: 'sms'
  },
  {
    text: 'VideoID',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    prop: 'videoid'
  },
  {
    text: 'KnowClient',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    prop: 'personal'
  },
  
  {
    text: 'KnownClient',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    prop: 'personal'
  },
  {
    text: 'Certificado',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green',
    prop: 'cert'
  },
  {
    text: 'CirbeICI',
    textClass: 'text-pink-600',
    bgClass: 'bg-pink-200',
    previewClass: 'bg-pink-600/10',
    prop: 'cirbe'
  },

  


];

export const requestedDocumentTypes = [
  {
    id: 0,
    label: 'Vida Laboral',
    prop: 'Laboral',
    ClavePIN: false,
    SMS: true,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1]
  },
  {
    id: 1,
    label: 'Situacion SS',
    prop: 'SituacionSS',
    ClavePIN: true,
    SMS: true,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 2,
    label: 'Bases de cotizacion',
    prop: 'Cotizacion',
    ClavePIN: true,
    SMS: true,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 3,
    label: 'Pensiones',
    prop: 'Pensiones',
    ClavePIN: true,
    SMS: true,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [2]
  },
  {
    id: 4,
    label: 'Revalorización pensiones',
    prop: 'Reval_pensiones',
    ClavePIN: true,
    SMS: true,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 5,
    label: 'SituacionAEAT',
    prop: 'SituacionAEAT',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1]
  },
  {
    id: 6,
    label: 'Certificado de la renta',
    prop: 'Renta',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [2]
  },
  {
    id: 7,
    label: 'Renta del útimo año',
    prop: '100_2021',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 8,
    label: 'Renta de hace 2 años',
    prop: '100_2020',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 9,
    label: 'Modelo 190',
    prop: '190',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 10,
    label: 'Modelo 390',
    prop: '390',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1]
  },
  {
    id: 11,
    label: 'Modelo 347',
    prop: '347',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 12,
    label: 'Modelo 303',
    prop: '303',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1]
  },
  {
    id: 13,
    label: 'Modelo 111',
    prop: '111',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1, 2]
  },
  {
    id: 14,
    label: 'Modelo 130',
    prop: '130',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: true,
    packs: [1]
  },
  {
    id: 15,
    label: 'Modelo 131',
    prop: '131',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: false,
    packs: []
  },
  {
    id: 16,
    label: 'Modelo 200',
    prop: '200',
    ClavePIN: false,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: false,
    packs: []
  },
  {
    id: 17,
    label: 'Censo',
    prop: 'TarjetaNIF',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: false,
    packs: []
  },
  {
    id: 18,
    label: 'CIRBE',
    prop: 'CIRBE',
    ClavePIN: false,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'pdf',
    enabled: false,
    packs: []
  },
  {
    id: 19,
    label: 'Catastro',
    prop: 'inmuebles',
    ClavePIN: true,
    SMS: false,
    VIDEO: true,
    PERSONAL: true,
    type: 'xls',
    enabled: false,
    packs: []
  }
];

export const solicitudesAttachments = [
  {
    requestId: '1mlh02m8ukjhqrz1837pbrpt4',
    filesExpected: [0, 1, 2, 3, 4, 5, 6, 7, 8, 19],
    files: [0, 1, 2, 6, 7, 8, 19]
  },
  {
    requestId: '7d4di2hdvjt8qr5v3ylt8etj',
    filesExpected: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    files: [0, 1, 2, 6]
  },
  {
    requestId: '5nwyi49g2n8ze80h4naxvvhmy',
    filesExpected: [1, 2, 3, 4, 19],
    files: [0, 1, 2, 6]
  },
  {
    requestId: '8ode0ludc38mewjgnasq4zqyc',
    filesExpected: [1, 2, 3, 4, 19],
    files: [1, 2, 3, 19]
  },
  {
    requestId: '9phw71p78x7o2lvv8x6qpetka',
    filesExpected: [1, 2, 3],
    files: [1, 2, 3]
  },
  {
    requestId: 'butylszl5axhrdn13sgydmoek',
    filesExpected: [1, 2, 3],
    files: [1, 2, 3]
  },
  {
    requestId: 'efrml28buvdw0pschpx0u10p',
    filesExpected: [1, 2, 3],
    files: [1, 2, 3]
  }


];

export const solicitudesAttachmentsTypes: any[] = [
  {
    id: 1,
    name: 'business-case-large.jpg',
    extension: 'pdf',
    path: 'assets/img/demo/1.jpg',
    size: '55 KB'
  },
  {
    id: 2,
    name: 'DNI.pdf',
    extension: 'pdf',
    path: 'assets/img/demo/2.jpg',
    size: '62 KB'
  },
  {
    id: 3,
    name: 'PERSONAL-data.txt',
    extension: 'txt',
    path: 'assets/img/demo/3.jpg',
    size: '35 KB'
  },
  {
    id: 4,
    name: 'workplace.jpg',
    extension: 'jpg',
    path: 'assets/img/demo/4.jpg',
    size: '51 KB'
  },
  {
    id: 5,
    name: 'issue-332.jpg',
    extension: 'jpg',
    path: 'assets/img/demo/5.jpg',
    size: '10 KB'
  },
  {
    id: 6,
    name: 'notebook-26.jpg',
    extension: 'jpg',
    path: 'assets/img/demo/6.jpg',
    size: '48 KB'
  },
  {
    id: 7,
    name: 'business-2.jpg',
    extension: 'jpg',
    path: 'assets/img/demo/7.jpg',
    size: '58 KB'
  },
  {
    id: 7,
    name: 'example-67.jpg',
    extension: 'jpg',
    path: 'assets/img/demo/8.jpg',
    size: '87 KB'
  }
];

export const realSolicitudes = [
  {
    "id_inc_core": 1,
    "vc_code": "82b7961f070b19643d9794650197a0d29d5a0b5ed0ef87c91544991401ce99fc",
    "vc_num_telefono": "+34675042941",
    "vc_nombres": "Alberto2",
    "vc_p_apellido": "Aroca2",
    "vc_s_apellido": "Serrano2",
    "vc_num_dni": "49585064N",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "alberto.aroca+segundoparticipante@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60277",
    "sn": "5118330",
    "ec": "dbFX3EM9",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "2000-06-29",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 13:19:59",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 9,
    "vc_code": "f2e14f2a38d00dcb0a96684968aae4b74119f7f1410bf13fc5f4039e8a193eba",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "2354",
    "vc_p_apellido": "235",
    "vc_s_apellido": "235",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60300",
    "sn": "5118336",
    "ec": "4a_Ad2mV",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 6,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 0,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 15:57:42",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 10,
    "vc_code": "b00cac03b010c186476c72d61e5ce91394a3740d421069d00f6f7704c5f291e9",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "2354",
    "vc_p_apellido": "235",
    "vc_s_apellido": "235",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60301",
    "sn": "5118337",
    "ec": "fk6GYPJ6",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 6,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 0,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 15:58:04",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 11,
    "vc_code": "9daf7b4f3cf80eaa09659c6e87d4b53bdcb8e45a5fb8a8f3e275fcaae4f4ecd4",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "2354",
    "vc_p_apellido": "235",
    "vc_s_apellido": "235",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60302",
    "sn": "5118338",
    "ec": "2Fdc,kG2",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 15:58:26",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 14,
    "vc_code": "9630a908d2aab278ef65bf06e86be88d1cd002de42510ee254d1e7c0d87de76a",
    "vc_num_telefono": "+344576",
    "vc_nombres": "2352",
    "vc_p_apellido": "235",
    "vc_s_apellido": "2345",
    "vc_num_dni": "235",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60322",
    "sn": "5118340",
    "ec": "k_F9k:2d",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 6,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 0,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 16:34:04",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 15,
    "vc_code": "7329f94d8c2344dd99553fe8f55c5f1f24437a1232720f505f858b2b02e3f616",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "3456",
    "vc_p_apellido": "346",
    "vc_s_apellido": "346",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60323",
    "sn": "5118341",
    "ec": "P3jB6h.f",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 16:35:23",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 16,
    "vc_code": "8c617863c7c3b5c4b9c7773c40f4128cde4e364278bae042e57b911a68055d33",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "3463",
    "vc_p_apellido": "346",
    "vc_s_apellido": "mar",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60326",
    "sn": "5118342",
    "ec": "8J37x4.D",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 16:50:32",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 17,
    "vc_code": "1450de4ab21e916316fdd08595f5fc7373aa2b52bb9f79b6ba5a11719e4b06ac",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "235",
    "vc_p_apellido": "23523",
    "vc_s_apellido": "34634",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60329",
    "sn": "5118343",
    "ec": "gm!;3e4W",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1999-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 17:18:09",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 18,
    "vc_code": "1f72c95a97991a4cf86f3ded18fc1a77ce809feb86531c71b4979301ed6aa7b3",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "23523",
    "vc_p_apellido": "2345234",
    "vc_s_apellido": "23452",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60330",
    "sn": "5118344",
    "ec": "7682;Nh_",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-09 17:24:09",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 23,
    "vc_code": "eae94e166dc06143ce8565355aadb8cedbbf28c4420afc272ab1fc2f33ed1fc4",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "346",
    "vc_p_apellido": "346",
    "vc_s_apellido": "3456",
    "vc_num_dni": "346",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60392",
    "sn": "5118345",
    "ec": "x8,K7jTK",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 6,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 0,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-10 08:22:03",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 24,
    "vc_code": "2f3d9f75f29ce5f2792f2076a092e455c2b53c8e75925285a83f552013ca9a19",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "346",
    "vc_p_apellido": "346",
    "vc_s_apellido": "3456",
    "vc_num_dni": "346",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60393",
    "sn": "5118346",
    "ec": "d4bj2h2E",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-10 08:31:06",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": true
  },
  {
    "id_inc_core": 25,
    "vc_code": "66772b7d4d01487cbfeb4d90a8f2aa0fa4e57a2208ebe0204947b3204daae0ff",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "346",
    "vc_p_apellido": "346",
    "vc_s_apellido": "346",
    "vc_num_dni": "3463",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60394",
    "sn": "5118347",
    "ec": "YGEjR96v",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-10 08:52:10",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 26,
    "vc_code": "8b685cda23d27e8b891f25cee04d66b37f1e52b314d9d1d8e2589d8acb01d7a4",
    "vc_num_telefono": "+34675042941",
    "vc_nombres": "Alberto",
    "vc_p_apellido": "Aroca",
    "vc_s_apellido": "Serrano",
    "vc_num_dni": "49585068N",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "alberto.aroca@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60395",
    "sn": "5118348",
    "ec": "2bQ262HE",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 6,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 0,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "2000-06-28",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-10 09:09:02",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 27,
    "vc_code": "a9ad2404517eac42b442f421e973c140655b71db19a672631b5fca62921b47ed",
    "vc_num_telefono": "+34635988296",
    "vc_nombres": "346",
    "vc_p_apellido": "346",
    "vc_s_apellido": "3456",
    "vc_num_dni": "00000000T",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "jose.hernandez@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60396",
    "sn": "5118349",
    "ec": "4V4QqMy_",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "1994-06-14",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-10 09:13:11",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  },
  {
    "id_inc_core": 28,
    "vc_code": "30252c13bfe3de4f849fd868ff7dcf1274a0c1cc68018252ddd08e388f67be7a",
    "vc_num_telefono": "+34675042941",
    "vc_nombres": "Alberto2",
    "vc_p_apellido": "Aroca2",
    "vc_s_apellido": "Serrano2",
    "vc_num_dni": "49585064N",
    "tipo_documento": null,
    "fecha_vencimiento_dni": null,
    "numero_soporte_nie": null,
    "vc_email": "alberto.aroca+segundoparticipante@gibobs.com",
    "sat_fecha_identificacion": null,
    "sat_lugar_de_identificacion": null,
    "sat_identificado_por": null,
    "titulo_juridico": null,
    "empoderamiento_juridico": null,
    "descripcion_juridico": null,
    "organizacion_nombre_juridico": null,
    "cif_juridico": null,
    "email_juridico": null,
    "responsable_nombre_juridico": null,
    "responsable_primer_apellido_juridico": null,
    "responsable_segundo_apellido_juridico": null,
    "servicio_id": "1,2,3,4",
    "financiador_id": 8,
    "usuario_id": 10,
    "PIN": "belorado74",
    "idCert": null,
    "pK": "60397",
    "sn": "5118350",
    "ec": "3x9N.3Hx",
    "otp": null,
    "pass_define_usuario": null,
    "modo_VIDEO_id": 1,
    "url_VIDEO_id": "https://cms.access.bit4id.org:13035/lcmpl/VIDEOid/ZjIxYTQyMjdlZWEwNDA5NzgyYWY4YzJjNjk2MmEwNDU6ZWdoS1l2a25CZ3ZXdmtmSnkzQWZtV2pSQjBBLUZoeFVHQmNxM0xiSk9pcTVQdS1fbGlEb0ZPcFh6OVZ4QjJ5Zjk5bjRmOXhJcEwwdXM1Y0dMcXFQdVNFMjE3Vk9KU251a1VyZjdXaE9kcEk9OmFhMGEyNzE3LTczNjYtNGVhMC05YzEzLTYzNmMxNDdkYjA3OA==",
    "PIN_ss": null,
    "PIN_aeat": null,
    "last_3_numbers": null,
    "intento": 0,
    "max_intentos": 0,
    "certificado_uanataca_id": null,
    "asnef": 0,
    "ch_status": 7,
    "sub_status": 0,
    "ch_tipo": 0,
    "ch_sub_tipo": 0,
    "list": 1,
    "url_success": null,
    "url_fail": null,
    "url_abandonment": null,
    "fill_form": null,
    "registered_from": null,
    "dt_fecha_consentimiento": null,
    "dt_fecha_inicio_proceso": null,
    "dt_fecha_nacimiento": "2000-06-29",
    "dt_fecha_consulta": "2022-02-08 10:56:03",
    "dt_fecha_respuesta": null,
    "dt_created_at": "2021-12-10 09:20:31",
    "dt_updated_at": "2022-02-08T09:56:03.000000Z",
    "vc_extra": null,
    "vc_extra_cirbe": 0,
    "deleted_at": null,
    "has_file": false
  }
]

export const solicitudesData = [
  {
    id: 0,
    firstName: 'Roger',
    lastName: 'Olivares',
    email: 'roger@gmail.com',
    phone: '1222111222',
    requestDate: '03/01/2023',
    responseDate: '03/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[1]],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    id: 'zZgheFeWMdAKMJDB9uh3',
    firstName: 'Tito ',
    lastName: 'Forns',
    email: 'tito@gmail.com',
    phone: '+351833772',
    requestDate: '02/01/2023',
    responseDate: '04/01/2023',
    type: 'PIN',
    labels: [solicitudesLabels[2]],
    attachments: [
      solicitudesAttachments[0],
      solicitudesAttachments[1],
      solicitudesAttachments[2],
      solicitudesAttachments[3],
      solicitudesAttachments[4]
    ],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    id: 2,
    firstName: 'Cosme',
    lastName: 'Fulanito',
    email: 'cosme@gmail.com',
    phone: '543334',
    requestDate: '01/01/2023',
    responseDate: '02/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[2]],
    attachments: [
      solicitudesAttachments[0],
      solicitudesAttachments[1],
      solicitudesAttachments[2],
    ],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    id: 3,
    firstName: 'Milton',
    lastName: 'Paez',
    email: 'mp86@hotmail.com',
    phone: '56644 3443',
    requestDate: '05/01/2023',
    responseDate: '05/01/2023',
    type: 'VIDEO ID',
    labels: [solicitudesLabels[0]],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    id: 4,
    firstName: 'Cassey',
    lastName: 'Mendez',
    email: 'casi@gmail.com',
    phone: '43232',
    requestDate: '28/12/2022',
    responseDate: '29/12/2022',
    type: 'PIN',
    labels: [solicitudesLabels[0]],
    attachments: [
      solicitudesAttachments[0],
      solicitudesAttachments[1],
      solicitudesAttachments[2],
    ],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
];

export const solicitudesHistoricData = [

  {
    firstName: 'Homero',
    lastName: 'Simpson',
    email: 'homer@outlook.com',
    phone: '3456243',
    requestDate: '03/01/2023',
    responseDate: '03/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[0]],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    firstName: 'Jhon',
    lastName: 'Doe',
    email: 'jdoe@gmail.com',
    phone: '54645343',
    requestDate: '04/01/2023',
    responseDate: '04/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[0]],
    attachments: [
      solicitudesAttachments[0],
      solicitudesAttachments[1],
      solicitudesAttachments[2],
    ],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    firstName: 'Ben',
    lastName: 'Thomas',
    email: 'thomas@icloud.com',
    phone: '4563443434',
    requestDate: '03/01/2023',
    responseDate: '03/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[0]],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    firstName: 'Bruce',
    lastName: 'Wayne',
    email: 'batman@ghotica.com',
    phone: '45654453',
    requestDate: '01/01/2023',
    responseDate: '01/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[0]],
    attachments: [
      solicitudesAttachments[0],
      solicitudesAttachments[1],
      solicitudesAttachments[2],
    ],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    firstName: 'Clark',
    lastName: 'Kent',
    email: 'superman@gmail.com',
    phone: '3454343',
    requestDate: '03/01/2023',
    responseDate: '03/01/2023',
    type: 'VIDEO ID',
    labels: [solicitudesLabels[0]],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    firstName: 'Roger',
    lastName: 'Olivares',
    email: 'roger@gmail.com',
    phone: '1222111222',
    requestDate: '03/01/2023',
    responseDate: '03/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[0]],
    attachments: [
      solicitudesAttachments[0],
      solicitudesAttachments[1],
      solicitudesAttachments[2],
    ],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  },
  {
    firstName: 'Rupert',
    lastName: 'Grint',
    email: 'ruperto@gmail.com',
    phone: '87674435',
    requestDate: '02/01/2023',
    responseDate: '03/01/2023',
    type: 'SMS',
    labels: [solicitudesLabels[0]],
    cs: 'e4472a00d9d6ec11c08678c47e2f095c7901852d2b9d03ed9ab27ff241d7eb51'
  }
];


