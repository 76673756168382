import { AfterViewChecked, Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/services/utils.service';
import { TranslationService } from 'src/services/translation.service';
import { ChangeDetectorRef } from '@angular/core';
import { TrelloService } from 'src/services/trello.service';
import { NavigationItem, NavigationSubheading } from 'src/@vex/interfaces/navigation-item.interface';
import { AuthService } from 'src/services/auth.service';
import { WebhooksService } from 'src/services/webhooks.service';
import { NotificationsService } from 'src/services/notifications.service';
import { getCurrentTranslation } from '../static-data/TRANSLATION';
import { SolicitudesService } from 'src/services/solicitudes.service';
import * as $ from 'jquery';
import { PresentialService } from 'src/services/presential.service';
import moment from 'moment';
// import * as WebSocket from 'ws';
import Quill from 'quill';
const Link = Quill.import('formats/link');

// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];
// Format Quill html editor links
class CustomLinkSanitizer extends Link {
    static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = Link.PROTOCOL_WHITELIST.some(function(protocol: string) {
        return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'https' to not to be a relative URL
    return `https://${sanitizedUrl}`;
    }
}

Quill.register(CustomLinkSanitizer, true);
// Format Quill html editor links end's

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {

  public loading: any = false;
  systemLoading = false;
  public financiadorID;
  public userID;
  sLang;
  language;
  requestToValidate;

  //socket = new WebSocket(this.url);
  socket = null;

  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private auth: AuthService,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private notiService: NotificationsService,
    public utils: UtilsService,
    private cdRef:ChangeDetectorRef,
    public translateService: TranslationService,
    public trelloService: TrelloService,
    public webhooksService: WebhooksService,
    private presentialService: PresentialService,
    public solicitudesService: SolicitudesService,
    public http: HttpClient
  ) {

    this.sLang = getCurrentTranslation().subscribe(t => {
      this.language = t;
      this.setSidenavItems(this.language);
      this.cdRef.markForCheck();
    });

    this.auth.bindUserData().subscribe(d => {
      if(d) {
        this.financiadorID = d.funder_id;
        this.userID = d.user_id;
        this.setSidenavItems(this.language);
        this.bindFirebaseNotifications(d.funder_id);
        d.role == 'BackOffice' && this.setRequestsToValidate();
        
        //this.setWssService(d);
      }
    })

    translateService.getLoadingLanguage().subscribe(b => {
      this.systemLoading = b;
    });

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }


    
    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    // this.http.get(this.url).subscribe(d => {
    //   console.warn(d);
    // })

    const color: ColorVariable = colorVariables['green'];
    let sidenavState = null; 

    if(localStorage.getItem('belender-sidenav-closed')) {
      sidenavState = JSON.parse(localStorage.getItem('belender-sidenav-closed')) ? 'collapsed' : 'expanded' ;
    } 

    if (color) {
      this.configService.updateConfig({
        style: {
          colors: {
            primary: color
          }
        },
        sidenav: {
          state: sidenavState || 'collapsed'
        }
      });
    }

    let st = this.configService.getStyleConfig(window.location.hostname);
    console.log('Vex config: ', st, window.location.hostname);

    if(st) {
      this.configService.setConfig(st.id);
      $('#title-page').text(st.name);
    }
    
    
   
  }



  setWssService(d) {
    this.socket = new WebSocket('wss://notifications.dev.belender.net?requests-code=' + d.user_id);
    var self = this;
   
    // Abre la conexión
    this.socket.onopen = function(event) {
        console.log('Conexión establecida');
    };

    // Maneja los mensajes entrantes
    this.socket.onmessage = function(event) {
        var mensaje = event.data;
        let objMessage = JSON.parse(mensaje);
        self.sendToastToservice(objMessage);
        if(objMessage.request_id) {
          self.updateRequest(objMessage)
        }
    };

    // Maneja los errores de la conexión
    this.socket.onerror = function(error) {
        console.log('WebSocket Error: ' + error);
    };

    // Cierra la conexión
    this.socket.onclose = function(event) {
        console.log('Conexión cerrada');
    };
    
  }

  sendToastToservice(objMessage) {
    console.error('on message: ', objMessage);
    let ks = Object.keys(objMessage);
    if(objMessage && ks && ks.length) {
      if(objMessage.updated_at) {
        let today = moment();
        let requestDay = moment(objMessage.updated_at);
        let df = moment(requestDay).diff(today, 'day');
        if(df < 0) {
          return;
        }
      }
      let html = `
      <h1 class="text-lg font-bold">Cambio en solicitud</h1>`;
  
      if(objMessage.flow_name) {
        html += '<p><small><b>Flujo: </b>' + objMessage.flow_name + '</small></p>'
      }
  
      if(objMessage.status_message) {
        html += '<p><small><b>Estado: </b>' + objMessage.status_message + '</small></p>'
      }
  
      if(objMessage.person_document_number) {
        html += '<p><small><b>DNI/NIE:</b> ' + objMessage.person_document_number + '</small></p>'
      }
  
      if(objMessage.updated_at) {
        html += '<p><small ><b>Día y hora:</b> ' + moment(objMessage.updated_at).format('DD/MM/YYYY HH:mm') + 'hs</small></p>'
      }

      this.notiService.showHtmlToast(html, 10000, 'info');
    }
    
  }

  updateRequest(objMessage) {
    this.solicitudesService.updateRequest(objMessage);
  }


  ngAfterViewChecked() {
    this.utils.getLoading().subscribe(b => {
      this.loading = b;
      this.cdRef.detectChanges();
    });
  }

  

  bindFirebaseNotifications(funderID) {
    this.notiService.bindFunderNotifications(funderID);
  }

  setRequestsToValidate() {
    this.requestToValidate = [];
    this.presentialService.getToValidate(this.financiadorID).then((d: any) => {
      if(d && d.data && d.data.length) {
        this.requestToValidate = d.data;
        this.navigationService && this.navigationService.items && this.navigationService.items.forEach((g: any) => {
          g && g.children && g.children.forEach(i => {
            if(i && i.route == 'admin-config/validation' && i.badge) {
              i.badge.value = '' + this.requestToValidate.length;
              this.cdRef.markForCheck();
            }
          });
        });
        this.navigationService.setItems();
      }
    })

  }

  setSidenavItems(l?) {
    this.navigationService.items = [
      this.getDashboardZone(l),
      this.getRequestZone(l),
      this.getConfigZone(l),
      this.getAdminZone(l),
      //this.getKycZone(),
      //this.getWidgetItem(),
    ];
    this.cdRef.markForCheck();
  }

  getDashboardZone(l?): NavigationItem {
    let obj: NavigationSubheading = {
      type: 'subheading',
      label: '',
      children: []
    }
    
    obj.children.push({
      type: 'link',
      label: 'Dashboard',
      route: '/dashboard',
      icon: 'mat:home',
      className: 'text-primary'

      
    });
    // if(this.auth.getUserRole() == 'BackOffice') {
    //   obj.children.push(
    //     {
    //       type: 'link',
    //       label: 'Stats',
    //       route: 'stats',
    //       icon: 'mat:query_stats',
    //     }
    //   )
    // }
    return obj;
  }

  getRequestZone(l?): NavigationSubheading {
    return  {
      type: 'subheading',
      label: l ? l.sidenav.requests : 'SOLICITUDES',
      children: [
        {
          type: 'link',
          label: l ? l.sidenav.all_request : 'Todas las solicitudes',
          route: '/solicitudes/all',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: l ? l.sidenav.new_request : 'Nueva solicitud',
          route: '/solicitudes/new',
          icon: 'mat:add_circle'
        },
      ]
    }
  }
  

  getWidgetItem(l?): NavigationSubheading {
    return {
      type: 'subheading',
      label: l ? l.sidenav.widget : 'Widget',
      children: [
        {
          type: 'link',
          label: l ? l.sidenav.widget : 'Widget',
          route: '/widget-boxed/sms/' + this.userID + '/626bc43f-f0e2-464b-b550-b5cd3a8c24d4' ,
          icon: 'mat:extension',
          target: '_blank'
        }
      ]
    }
  }

  getConfigZone(l?): NavigationSubheading {
    let obj: NavigationSubheading = {
      type: 'subheading',
      hide: true,
      label: l ? l.sidenav.config : 'Configuración',
      children: []
    };
    if(this.auth.getUserRole() == 'BackOffice' || this.auth.getUserRole() == 'FunderAdmin' || this.auth.getUserRole() == 'FunderAdminLimited') {
      obj.hide = false;

      obj.children.push(
        {
          type: 'link',
          label: l ? l.sidenav.customization : 'Widget',
          route: 'config/customization',
          icon: 'mat:format_paint'
        }
      )
    }
    return obj;
  }

  getAdminZone(l?): NavigationSubheading {
    let obj: NavigationSubheading = {
      type: 'subheading',
      hide: true,
      label: l ? l.sidenav.admin_config : 'Admin config',
      children: []
    };
    if(this.auth.getUserRole() == 'BackOffice') {
      obj.hide = false;
      // obj.children.push(
      //   {
      //     type: 'link',
      //     label: 'Testing',
      //     route: 'admin-config/test',
      //     icon: 'mat:info'
      //   }
      // )
      obj.children.push(
        {
          type: 'link',
          label: 'Flujos',
          route: 'admin-config/flows',
          icon: 'mat:account_tree',
          
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: l ? l.sidenav.packs : 'Packs',
          route: 'admin-config/packs',
          icon: 'mat:attachment',
          
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: l ? l.sidenav.users : 'Usuarios',
          route: 'admin-config/users',
          icon: 'mat:people'
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: l ? l.sidenav.id_validation : 'Emit certificate',
          route: 'admin-config/validation',
          icon: 'mat:how_to_reg',
          badge: {
            value: this.requestToValidate ? ('' + this.requestToValidate.length) : '',
            bgClass: 'bg-red-500',
            textClass: 'text-white',
          },
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: 'Certificados emitidos',
          route: 'admin-config/certificates',
          icon: 'mat:fact_check'
        }
      )
      obj.children.push(
        {
          type: 'link',
          label: 'Poderes digitales',
          route: 'admin-config/power-activate',
          icon: 'mat:schedule_send'
        }
      )
      
      obj.children.push(
        {
          type: 'link',
          label: 'Panic Button',
          route: 'admin-config/panic-button',
          icon: 'mat:notification_important'
        }
      )
    }
    return obj;
  }

  getKycZone(l?): NavigationSubheading {
    let obj: NavigationSubheading = {
      type: 'subheading',
      hide: true,
      label: 'KYC',
      children: []
    };
    if(this.auth.getUserRole() == 'BackOffice') {
      obj.hide = false;
      obj.children.push(
        {
          type: 'link',
          label: l ? l.sidenav.customizate : 'Customizate',
          route: 'kyc/custom',
          icon: 'mat:info'
        },
        {
          type: 'link',
          label: l ? l.sidenav.form : 'Form',
          route: 'kyc/form',
          icon: 'mat:info'
        }
      )
      
    }
    return obj;
  }
 
  
}
