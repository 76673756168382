// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  env: 'pro',
  baseUrl: 'https://api.belender.net',
  domainUrl: 'https://backoffice.belender.net',
  widgetUrl: 'https://widget.belender.net'
};


